(
        function (angular) {
            var app = angular.module('app');
            app.controller('DashboardIndexController', ["$scope", "auth", "api", "$state", "$stateParams", function ($scope, auth, api, $state, $stateParams) {
                    $scope.dashboard = {};

                    $scope.alert = $stateParams.alert;

                    $scope.init = function () {
                        api.get('dashboard', {}, function (responce) {
                            $scope.dashboard = responce;
                        }, function () {
                            $state.go('login');
                        });
                    };

                $scope.closeAlert = function () {
                    delete $scope.alert;
                }
                }]);
        }
)(angular);
