/**
 * Created by valik35 on 21.10.16.
 */
(
	function (angular) {
		var app = angular.module('app');

		app.controller('AuthController', function ($scope, auth, $state, $stateParams) {

			$scope.alert = $stateParams.alert;

			$scope.login = function () {
				/*@todo: add validation*/
				auth.login($scope.user, function (response) {
					$scope.errors = response;
				});
			}

			$scope.closeAlert = function () {
				delete $scope.alert;
			}
		});
	}
)(angular);