/**
 * Created by valik35 on 21.10.16.
 */
(
	function (angular) {
		var app = angular.module('app');

		app.controller('ResetPassController', function ($scope, api, $state, $stateParams, $location) {

			$scope.data = {
				token: $stateParams.token,
				email: $location.search().email,
			};

			$scope.reset = function () {
				api.post('password/email', { email : $scope.email },
					function (response) {
						$state.go('login', {
							alert: {
								message: 'We have sent your password reset link on ' + $scope.email + '!',
								type: 'success'
							}
						});
					},
					function (data) {
						$scope.errors = data;
					});
			};

			$scope.changePassword = function () {
				api.post('password/reset', $scope.data,
					function (response) {
						$state.go('login', {
							alert: {
								message: 'Your password was successfully restored!',
								type: 'success'
							}
						});
					},
					function (data) {
						$scope.errors = data;
					});
			}
		});
	}
)(angular);