(function (angular) {
        var app = angular.module('app');
        app.controller('UserProfileController', function ($scope, $state, $stateParams, api, capitalizeFilter, confirmModal, countries, auth, localStorageService) {

            $scope.backUrl = 'dashboard';

            $scope.user = {
                phone_country_code: {
                    "name": "United Arab Emirates",
                    "dial_code": "+971",
                    "code": "AE"
                }
            };

            $scope.errors = {};
            $scope.countries = countries;

            loadUser();
            function loadUser() {
                api.get('profile/' + auth.user().id, null, function (response) {
                        $scope.user = response;
                        $scope.user.role = $stateParams.role;
                        if($scope.user.phone_country_code) {

                            countries.forEach(function (element, index, array) {
                                if(element.dial_code == '+' + $scope.user.phone_country_code) {
                                    $scope.user.phone_country_code = element;
                                }
                            });
                        } else {
                            $scope.user.phone_country_code = {
                                "name": "United Arab Emirates",
                                "dial_code": "+971",
                                "code": "AE"
                            };
                        }
                    },
                    function (data) {
                        $scope.errors = data;
                    });
            }

            function mapUser(user) {
                var oldUser = angular.extend({}, user);
                console.log(oldUser);

                oldUser.phone_country_code = oldUser.phone_country_code.dial_code.replace(/\+/, '');
                oldUser.phone_number = oldUser.phone_number == null ? '' : oldUser.phone_number;

                return oldUser;
            }

            $scope.updateUser = function () {
                api.patch('profile/' + auth.user().id, angular.extend({}, mapUser($scope.user), {
                        action : 'update'
                    }),
                    function (response) {
                        var user = auth.user();
                        user.name = response.name;
                        localStorageService.set('user', user);
                        $state.go('dashboard', {
                            alert: {
                                message: 'Profile was successfully updated',
                                type: 'success'
                            }
                        });
                    },
                    function (data) {
                        $scope.errors = data;
                    });
            };

            $scope.changePassword = function () {
                api.patch('profile/' + auth.user().id,  angular.extend({}, $scope.user, {
                        action : 'change_password'
                    }),
                    function (response) {
                        $state.go('dashboard', {
                            alert: {
                                message: 'Password was successfully changed',
                                type: 'success'
                            }
                        });
                    },
                    function (data) {
                        $scope.errors = data;
                    });
            };

            $scope.cancel = function () {
                confirmModal.open({
                    message: 'Do you want to leave this page and lose your changes?',
                    onConfirm: function () {
                        if ($scope.previousState.name) {
                            $state.go($scope.previousState.name);
                        } else {
                            $state.go('dashboard');
                        }
                    }
                });
            };
        });
    }
)(angular);
