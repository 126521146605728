(
	function (angular) {
		var app = angular.module('app');

		app.controller('ErrorsController', function ($scope, auth, $state, $stateParams, $location) {

            $scope.path = $location.path();

            $scope.alert = $stateParams.alert;

		});
	}
)(angular);