/**
 * Created by valik35 on 21.10.16.
 */
(
	function (angular) {
		var app = angular.module('app');

		app.service('api', function(Upload, localStorageService, $state) {

			this.api_url = 'web-api/';

			this.actions = {
				login: 'login',
				user: 'user',
				building: 'building',
				qr_to_address: 'qr-to-address',
				dictionary: {
					building_owners: 'dictionary/building_owners'
				},
				editor: 'editor'

			};

			this.request = function (method, action, params, data, onSuccess, onError) {
				if(onError === undefined) {
					onError = function () {

					}
				}
				// console.log(data);

				var headers = {};
				if(localStorageService.get('auth_token') && localStorageService.get('role')) {
					angular.extend(headers, {
						"Authorization": "Bearer " + localStorageService.get('auth_token')
					});
				}

				if(method == 'PATCH' || method == 'PUT' || method == 'DELETE') {
					angular.extend(data, {
						"_method": method
					});
					method = 'POST'
				}

				Upload.upload({
					method: method,
					// transformRequest: angular.identity,
					url: this.api_url + action,
					data: data,
					params: params,
					xsrfHeaderName: "X-XSRF-TOKEN",
					xsrfCookieName: "XSRF-TOKEN",
					responseType: "json",
					headers: headers,
				}).then(function(response) {
					response = response.data;

					return onSuccess(response);
				}, function(response) {
					console.log(response);
					if(response.status == 401) {
						localStorageService.remove('auth_token', 'role', 'user');
						$state.go('login');
					}
					response = response.data;

					return onError(response);
				});
			}

			this.get = function (action, params, onSuccess, onError) {
				this.request('GET', action, params, null, onSuccess, onError)
			}

			this.post = function (action, data, onSuccess, onError) {
				this.request('POST', action, null, data, onSuccess, onError)
			}

			this.patch = function (action, data, onSuccess, onError) {
				this.request('PATCH', action, null, data, onSuccess, onError)
			}

			this.put = function (action, data, onSuccess, onError) {
				this.request('PUT', action, null, data, onSuccess, onError)
			}

			this.delete = function (action, data, onSuccess, onError) {
				this.request('DELETE', action, null, data, onSuccess, onError)
			}

		});
	}
)(angular);