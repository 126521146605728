(function (app) {
    app.controller('InviteImportController', ["$scope", "api", "confirmModal", "$state", "$stateParams", function ($scope, api, confirmModal, $state, $stateParams) {
        $scope.back = function () {
            confirmModal.open({
                onConfirm: function () {
                    if ($stateParams.building) {
                        $state.go('building::edit', {
                            id: $stateParams.building,
                            tab: 2
                        });
                    } else {
                        $state.go('invite');
                    }
                },
                message: 'Do you want to leave this page and lose your changes?'
            });
        };

        $scope.import = function () {
            api.post('invite/import', {'invites': $scope.file}, function () {
                if ($stateParams.building) {
                    $state.go('building::edit', {
                        id: $stateParams.building,
                        tab: 2,
                        alert: {
                            message: 'File was successfully imported',
                            type: 'success'
                        }
                    });
                } else {
                    $state.go('invite', {
                        alert: {
                            message: 'File was successfully imported',
                            type: 'success'
                        }
                    });
                }
            }, function (error) {
                $scope.alert = {
                    message: error,
                    type: 'warning'
                };
            });
        };

        $scope.downloadTemplate = function () {
            window.location = 'invite/import/example';
        };

        $scope.closeAlert = function () {
            delete $scope.alert;
        };
    }]);
})(angular.module('app'));
