/**
 * Created by valik35 on 21.10.16.
 */
(
	function (angular) {
		var app = angular.module('app');
		app.controller('UserListController', function ($scope, $uibModal, $state, $stateParams, confirmModal, moment, api) {

			$scope.alert = $stateParams.alert;

			$scope.filter = {
				role: $stateParams.role,
				id: null,
				name: null,
				email: null,
				current_page: 1,
				date_field: 'created_at',
				module: 'site'
			};

			$scope.sort = {
				field: 'id',
				order: 'desc'
			};

            $scope.selectedBuilding = {
                building: {}
            };

			loadUsers();

            if ($stateParams.role == 'owner') {
                getBuildings();
            }

			function loadUsers(){
                $scope.filter.building = $scope.selectedBuilding.building.id;

				api.get(api.actions.user, angular.extend({}, $scope.current_page, mapFilter($scope.filter), $scope.sort), function (response) {
					$scope.users = response.data;

					$scope.filter.total    = response.total;
					$scope.filter.page     = response.current_page;
					$scope.filter.per_page = response.per_page;

				});
			}

            function getBuildings() {
                api.get('dictionary/buildings', null, function (response) {
                    $scope.buildings = response;
                    $scope.buildings.unshift({name: 'All', id: null});
                    $scope.selectedBuilding.building = $scope.buildings[0];
                });
            }

			function mapFilter(filter) {
				var copy = angular.extend({}, filter);

                if (copy.date_at_from) {
                    copy.date_at_from = moment(copy.date_at_from).format();
                }

                if (copy.date_at_to) {
                    copy.date_at_to = moment(copy.date_at_to).hours(23).minutes(59).seconds(59).format();
                }

				return copy;
			}

			$scope.delete = function (user) {
				if ($stateParams.role === 'owner') {
					$scope.deletingOwner = user;
					api.get('building-owner/' + user.id, {}, function (response) {
                        $scope.modalScope = {
                            deleteAll: null,
                            assignAll: null
                        };
						$scope.ownerBuildings = response;

						if ($scope.ownerBuildings.length) {
							$scope.deleteOwnerModal = $uibModal.open({
								scope: $scope,
								templateUrl: 'templates/partial/deleteOwner.html'
							});
						} else {
							confirmModal.open({
								onConfirm: function () {
									api.delete('delete-owner/' + $scope.deletingOwner.id, {}, function () {
										$scope.alert = {
											message: 'Owner was successfully deleted',
											type: 'success'
										};
										loadUsers();
									})
								},
								message: 'Do you really want to delete the owner ' + user.name
							});
						}
					});
				} else {
					confirmModal.open({
						onConfirm: function () {
							api.delete(api.actions.user + '/' + user.id, {}, function () {
								$scope.alert = {
									message: 'User was deleted successfully',
									type: 'success'
								};
								loadUsers();
							});
						},
						message: 'Do you really want to delete the user ' + user.name
					});
				}
			};

            $scope.selectAllToDelete = function () {
                for (var i = 0; i < $scope.ownerBuildings.length; i++) {
                    $scope.ownerBuildings[i].delete = $scope.modalScope.deleteAll ? true : false;
                    $scope.ownerBuildings[i].assign = false;
                    $scope.modalScope.assignAll = false;
                }
            };

            $scope.selectAllToAssign = function () {
                for (var i = 0; i < $scope.ownerBuildings.length; i++) {
                    $scope.ownerBuildings[i].assign = $scope.modalScope.assignAll ? true : false;
                    $scope.ownerBuildings[i].delete = false;
                    $scope.modalScope.deleteAll = false;
                }
            };

            $scope.selectToDelete = function (building) {
                for (var i = 0; i < $scope.ownerBuildings.length; i++) {
                    if ($scope.ownerBuildings[i].id == building.id) {
                        $scope.ownerBuildings[i].delete = true;
                        $scope.ownerBuildings[i].assign = false;
                    }
                    $scope.modalScope.deleteAll = false;
                    $scope.modalScope.assignAll = false;
                }
            };

            $scope.selectToAssign = function (building) {
                for (var i = 0; i < $scope.ownerBuildings.length; i++) {
                    if ($scope.ownerBuildings[i].id == building.id) {
                        $scope.ownerBuildings[i].delete = false;
                        $scope.ownerBuildings[i].assign = true;
                    }
                    $scope.modalScope.assignAll = false;
                    $scope.modalScope.deleteAll = false;
                }
            };

            $scope.apply = function () {
                var data = [];

                if (!$scope.ownerBuildings.length) {
                    return ;
                }

                for (var i = 0; i < $scope.ownerBuildings.length; i++) {
                    if (!$scope.ownerBuildings[i].delete && !$scope.ownerBuildings[i].assign) {
                        var name = $scope.ownerBuildings[i].name;
                        $scope.modalScope.alert = {
                            message: 'Please select action for ' + name,
                            type: 'warning'
                        };
                        return ;
                    }
                }

                for (var i = 0; i < $scope.ownerBuildings.length; i++) {
                    var tmp = {};
                        tmp.id = $scope.ownerBuildings[i].id;
                    if ($scope.ownerBuildings[i].delete) {
                        tmp.delete = true;
                    }

                    if ($scope.ownerBuildings[i].assign) {
                        tmp.assign = true;
                    }

                    data.push(tmp);
                }

                api.delete('delete-owner/' + $scope.deletingOwner.id, {building_ids: data}, function () {
                    $scope.alert = {
                        message: 'Owner was successfully deleted',
                        type: 'success'
                    };
                    loadUsers();
                    $scope.deleteOwnerModal.close();
                });
            };

            $scope.closeModalAlert = function () {
                delete $scope.modalScope.alert;
            };

			$scope.closeDeleteOwner = function () {
				$scope.deleteOwnerModal.close();
			};

			$scope.edit = function (id) {
				$state.go($scope.filter.role + '::edit', {
					id: id
				});
			};

			$scope.pageChanged = function () {
				loadUsers();
			};

			$scope.refresh = function () {
				loadUsers();
			};

			$scope.sortBy = function (field) {
				if($scope.sort.field == field) {
					$scope.sort.order == 'desc' ? $scope.sort.order = 'asc' : $scope.sort.order = 'desc';
				} else {
					$scope.sort.order = 'desc';
				}

				$scope.sort.field = field;

				loadUsers();
			};

			$scope.open_date_at_from_popup = function () {
				$scope.opened_date_at_from_popup = true;
			};

			$scope.open_date_at_to_popup = function () {
				$scope.opened_date_at_to_popup = true;
			};

			$scope.closeAlert = function () {
				delete $scope.alert;
			}

		});
	}
)(angular);