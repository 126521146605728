(function (app) {
    app.controller('UserImportController', ['$scope', 'api', 'confirmModal', 'capitalizeFilter', '$stateParams', '$state', function ($scope, api, confirmModal, capitalizeFilter, $stateParams, $state) {

        $scope.backUrl = $stateParams.role == 'user' ? 'user' : 'owner';

        $scope.errors = {};

        $scope.data = {
            role: $stateParams.role
        };

        $scope.import = function () {
            api.post('user/import', $scope.data, function () {
                $state.go($scope.backUrl, {
                    alert: {
                        message: capitalizeFilter($scope.backUrl) + 's was successfully imported',
                        type: 'success'
                    }
                });
            }, function (error) {
                $scope.alert = {
                    message: error,
                    type: 'warning'
                };
            });
        };

        $scope.back = function () {
            confirmModal.open({
                onConfirm: function () {
                    $state.go($scope.backUrl);
                },
                message: 'Do you want to leave this page and lose your changes?'
            });
        };

        $scope.downloadTemplate = function () {
            window.location = 'user/import/example?role=' + $stateParams.role;
        };

        $scope.closeAlert = function () {
            delete $scope.alert;
        };
    }]);
})(angular.module('app'));
