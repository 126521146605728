(function (app) {
    app.controller('FloorImportController', ['$scope', 'api', 'confirmModal', '$state', 'auth', function ($scope, api, confirmModal, $state, auth) {

        $scope.import = function () {
            var data = {floors: $scope.file};
            if (auth.hasRole(auth.ROLE_BUILDING_OWNER)) {
                data.owner = true;
            }
            api.post('floor/import', data, function () {
                $state.go('floors', {
                    alert: {
                        message: 'Floors was successfully imported',
                        type: 'success'
                    }
                })
            }, function (errors) {
                $scope.alert = {
                    messages: errors,
                    type: 'warning'
                };
            });
        };

        $scope.back = function () {
            confirmModal.open({
                onConfirm: function () {
                    window.history.back();
                    // $state.go('floors');
                },
                message: 'Do you want to leave this page and lose your changes?'
            });
        };

        $scope.downloadTemplate = function () {
            window.location = 'floor/import/example';
        };

        $scope.closeAlert = function () {
            delete $scope.alert;
        }
    }]);
})(angular.module('app'));
