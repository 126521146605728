/**
 * Created by valik35 on 21.10.16.
 */
(
	function (angular) {
		var app = angular.module('app', [
			'ui.router',
			'ui.router.middleware',
            'ui.select',
            'ngSanitize',
            'angular-loading-bar',
			'ui.bootstrap',
			'angularMoment',
			'ngMap',
            'colorpicker.module',
			'LocalStorageModule',
			'ngFileUpload',
            'ngImgCrop'
		]);

		app.run(function ($rootScope, auth, $state) {
            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState) {
                $rootScope.previousState = fromState;
                if (auth.check() && toState.name === 'home') {
                    event.preventDefault();
                    $state.go('dashboard');
                } else if (!auth.check() && toState.middleware !== 'only-guest') {
                    event.preventDefault();
                    $state.go('login');
                }
            });

			$rootScope.auth = auth;
		});
	}
)(angular);