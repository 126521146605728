/**
 * Created by valik35 on 21.10.16.
 */
(
	function (angular) {
		var app = angular.module('app');

		app.controller('HomeController', function ($scope, auth, $state, $stateParams) {

			$scope.alert = $stateParams.alert;

			$scope.closeAlert = function () {
				delete $scope.alert;
			}
		});
	}
)(angular);