/**
 * Created by valik35 on 21.10.16.
 */
(
    function (angular) {
        var app = angular.module('app');
        app.controller('UserCreateController', function ($scope, $state, $stateParams, api, capitalizeFilter, confirmModal, countries) {

            $scope.backUrl = $stateParams.role == 'user' ? 'user' : 'owner'

            $scope.user = {
                role: $stateParams.role,
                phone_country_code: {
                    "name": "United Arab Emirates",
                    "dial_code": "+971",
                    "code": "AE"
                },
            }
            $scope.errors = {};
            $scope.countries = countries;

            $scope.sumbit = function () {
                api.post(api.actions.user, mapUser($scope.user),
                    function (response) {
                        $state.go($scope.backUrl, {
                            alert: {
                                message: capitalizeFilter($scope.user.role) + ' was succesfully created',
                                type: 'success'
                            }
                        });
                    },
                    function (data) {
                        $scope.errors = data;
                    });
            }

            function mapUser(user) {
                var oldUser = angular.extend({}, user);
                console.log(oldUser);

                oldUser.phone_country_code = oldUser.phone_country_code.dial_code.replace(/\+/, '');

                return oldUser;
            }

            $scope.cancel = function () {
                confirmModal.open({
                    message: 'Do you want to leave this page and lose your changes?',
                    onConfirm: function () {
                        $state.go($scope.backUrl);
                    }
                });
            };
        });
    }
)(angular);