(function (app) {
    app.directive('sortableTh', function() {
        return {
            scope: {
                field: '=',
                name:  '=',
                sort:  '=',
                sortBy: '='
            },
            template: `
                <a class="sort-field" href="javascript:void(0)" ng-click="sortBy(field)">{{ name }}</a>
                <a class="sort-direction" href="javascript:void(0)" ng-click="sortBy(field)">
                    <i ng-class="{'glyphicon-sort': sort.field != field,
                                                        'glyphicon-chevron-down': sort.field == field && sort.order == 'desc',
                                                        'glyphicon-chevron-up': sort.field == field && sort.order == 'asc'}"
                       class="glyphicon"></i>
                </a>
            `
        };
    })
})(angular.module('app'));