/**
 * Created by valik35 on 21.10.16.
 */
(
	function (angular) {
		var app = angular.module('app');

		app.service('confirmModal', function($uibModal) {

			this.open = function (params) {

				if(params.onConfirm === undefined) {
					params.onConfirm = function () {

					}
				}
				if(params.onCancel === undefined) {
					params.onCancel = function () {

					}
				}

				$uibModal.open({
					templateUrl: 'templates/partial/confirmModal.html',
					controller: function ($scope, $uibModalInstance) {
						$scope.message = params.message;
						if (params.useBreakWord !== false) {
                            params.useBreakWord = true;
                        }
                        $scope.useBreakWord = params.useBreakWord;

						$scope.confirm = function () {
							$uibModalInstance.close();

							return params.onConfirm()
						}

						$scope.cancel = function () {
							$uibModalInstance.close();

							return params.onCancel()
						}
					}
				});
			}
		});
	}
)(angular);