(function (app) {
    app.directive('googleplace', function() {
        return {
            require: 'ngModel',
            scope: {
                gpLocation: '='
            },
            link: function(scope, element, attributes, model) {
                var options = {
                    types: [],
                    componentRestrictions: {}
                };

                scope.$watch(function() {
                    return scope.gpLocation;
                }, function(value) {
                    if(value) {
                        if(value.country && value.country.code) {
                            options = {
                                types: ['(cities)'],
                                componentRestrictions: {'country': value.country.code}
                            };

                            scope.gPlace = new google.maps.places.Autocomplete(element[0], options);
                            google.maps.event.addListener(scope.gPlace, 'place_changed', function(results) {
                                var city = scope.gPlace.getPlace();
                                scope.$apply(function() {
                                    element.val(city.name);
                                    model.$setViewValue(city.name); //element.val() // city.name
                                });
                            });
                        }
                    }
                });
            }
        };
    })
})(angular.module('app'));