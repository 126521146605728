(function (app) {
    app.controller('FloorCreateController', ['$scope', 'api', 'confirmModal', '$state', '$stateParams', function ($scope, api, confirmModal, $state, $stateParams) {
        $scope.floorTypes = [
            {name: 'Roof', code: 'roof'},
            {name: 'Floor', code: 'floor'},
            {name: 'Basement', code: 'basement'},
            {name: 'Mezzanine', code: 'mezzanine'},
            {name: 'Ground floor', code: 'ground_floor'}
        ];

        $scope.maxFileSize = 100; // megabytes

        $scope.color_picker_options = {
            format: 'hex'
        };

        $scope.selectedBuilding = {
            building: ''
        };

        $scope.floor = {};

        $scope.disabled = false;

        $scope.init = function () {
            $scope.type = $scope.floorTypes[0];
            $scope.getBuildings();
        };

        $scope.getBuildings = function () {
            api.get('floor-buildings', {module: 'site'}, function (response) {
                $scope.buildings = response;
                if ($stateParams.building) {
                    $scope.disabled = true;
                    for (var i = 0; i < $scope.buildings.length; i++) {
                        if ($scope.buildings[i].id == $stateParams.building) {
                            $scope.selectedBuilding.building = $scope.buildings[i];
                        }
                    }
                }
            });
        };

        $scope.$watch('type', function () {
            if ($scope.type) {
                $scope.letter = $scope.type.name.charAt(0);
                if($scope.type.code == 'ground_floor') {
                    $scope.floor.number = 0;
                    $scope.floorDisabled = true;
                } else {
                    $scope.floorDisabled = false;
                }
            }
        });

        $scope.uploadFile = function (file) {
            if (!$scope.errors) {
                $scope.errors = [];
            }

            delete $scope.errors.model;

            if (file && file.size > ($scope.maxFileSize * 1024 * 1024)) {
                delete $scope.floor.model;
                $scope.errors.model = ['File is too big. The file may not be greater than '+ $scope.maxFileSize +' megabytes.'];
            }
        };

        $scope.selectBuilding = function (item) {
            $scope.floor.building = item.id;
        };

        $scope.back = function () {
            confirmModal.open({
                onConfirm: function () {
                    if ($stateParams.building) {
                        $state.go('building::edit', {
                            id: $stateParams.building,
                            tab: 1
                        });
                    } else {
                        $state.go('floors');
                    }
                },
                message: 'Do you want to leave this page and lose your changes?'
            });
        };

        $scope.save = function () {
            $scope.floor.type = $scope.type.code;
            $scope.floor.building = $scope.selectedBuilding.building.id;
            api.post('floor', $scope.floor, function () {
                if ($stateParams.building) {
                    $state.go('building::edit', {
                        id: $stateParams.building,
                        tab: 1,
                        alert: {
                            message: 'Floor was successfully created',
                            type: 'success'
                        }
                    });
                } else {
                    $state.go('floors', {
                        alert: {
                            message: 'Floor was successfully created',
                            type: 'success'
                        }
                    });
                }
            }, function (errors) {
                $scope.errors = errors;
            });
        };
    }]);
})(angular.module('app'));
