(function (app) {
    app.controller('InviteNewController', ['$scope', 'api', '$state', 'confirmModal', '$stateParams', function ($scope, api, $state, confirmModal, $stateParams) {

        $scope.building = {};

        $scope.buildings = [];

        $scope.invite = {};

        $scope.errors = {};

        $scope.init = function () {
            api.get('invite-buildings', {}, function (response) {
                $scope.buildings = response;
                if ($stateParams.building) {
                    for (var i = 0; i < $scope.buildings.length; i++) {
                        if ($scope.buildings[i].id == $stateParams.building) {
                            $scope.building = $scope.buildings[i];
                            $scope.disabled = true;
                        }
                    }
                }
            });
        };

        $scope.addInvite = function () {
            $scope.invite.building = $scope.building.id;
            api.post('invite', $scope.invite, function (response) {
                if (response.message) {
                    $scope.alert = {
                        message: response.message,
                        type: 'warning'
                    };
                } else {
                    if ($stateParams.building) {
                        $state.go('building::edit', {
                            id: $stateParams.building,
                            tab: 3,
                            alert: {
                                message: 'Invite for email ' + response.email + ' was sent',
                                type: 'success'
                            }
                        });
                    } else {
                        $state.go('invite', {
                            alert: {
                                message: 'Invite for email ' + response.email + ' was sent',
                                type: 'success'
                            }
                        });
                    }
                }
            }, function (response) {
                $scope.errors = response;
            });
        };

        $scope.selectBuilding = function (item) {
            $scope.building = item;
        };

        $scope.closeAlert = function () {
            delete $scope.alert;
        };

        $scope.back = function () {
            confirmModal.open({
                onConfirm: function () {
                    if ($stateParams.building) {
                        $state.go('building::edit', {
                            id: $stateParams.building,
                            tab: 2
                        });
                    } else {
                        $state.go('invite');
                    }
                },
                message: 'Do you want to leave this page and lose your changes?'
            });
        };
    }]);
})(angular.module('app'));
