(function (app) {

    app.controller("FloorIndexController", ["$scope", "api", "moment", "$stateParams", "$state", "confirmModal", "$controller",
        function ($scope, api, moment, $stateParams, $state, confirmModal, $controller) {

            $scope.floors = {};

            $scope.selectedBuilding = {
                building: {}
            };

            $scope.building = {};

            $scope.filters = {
                id: null,
                title: null,
                building: null,
                type: null,
                units_count: null,
                number: null,
                date_field: 'created_at',
                module: 'site'
            };

            $scope.sort = {
                field: 'id',
                order: 'desc'
            };

            $scope.floorTypes = [
                {name: 'All', code: null},
                {name: 'Basement', code: 'basement'},
                {name: 'Floor', code: 'floor'},
                {name: 'Roof', code: 'roof'},
                {name: 'Mezzanine', code: 'mezzanine'},
                {name: 'Ground floor', code: 'ground_floor'}
            ];

            $scope.alert = $stateParams.alert;

            $scope.type = $scope.floorTypes[0];

            $scope.init = function () {
                $scope.getFloors($scope.isEditBuilding);
                $scope.getBuildings();
            };

            $scope.getFloors = function (editBuilding) {
                $scope.isEditBuilding = editBuilding;
                if ($scope.isEditBuilding) {
                    $scope.buildingEditScope = $scope.$new();
                    $controller('BuildingEditController', {$scope: $scope.buildingEditScope});
                    $scope.building = {id: $scope.buildingEditScope.building};
                    $scope.filters.building = $scope.buildingEditScope.building;
                }

                if (!$scope.isEditBuilding) {
                    $scope.filters.building = $scope.selectedBuilding.building.id;
                }

                $scope.filters.type = $scope.type.code;

                if ($scope.date_at_to) {
                    $scope.filters.date_at_to = moment($scope.date_at_to).hours(23).minutes(59).seconds(59).format();
                }

                api.get('floors', angular.extend({}, $scope.filters, $scope.sort), function (response) {
                    $scope.floors = [];

                    angular.forEach(response.data, function(floor, key) {
                        if(floor.type == 'basement') {
                            floor.number = -floor.number;
                        }
                        $scope.floors.push(floor);
                    });

                    $scope.filters.total = response.total;
                    $scope.filters.per_page = response.per_page;
                    $scope.filters.page = response.current_page;
                });
            };

            $scope.sortBy = function (field) {
                if ($scope.sort.field === field) {
                    $scope.sort.order = $scope.sort.order === 'desc' ? 'asc' : 'desc';
                } else {
                    $scope.sort.order = 'desc';
                }

                $scope.sort.field = field;
                $scope.getFloors($scope.isEditBuilding);
            };

            $scope.getBuildings = function () {
                api.get('floor-buildings', {module: $scope.filters.module}, function (response) {
                    $scope.buildings = response;
                    $scope.buildings.unshift({name: 'All', id: null});
                    $scope.selectedBuilding.building = $scope.buildings[0];
                });
            };

            $scope.edit = function (floor) {
                $state.go('floors.edit', {
                    id: floor.id,
                    building: $scope.building.id
                });
            };

            $scope.download = function (floor) {
                if (parseInt(floor.size)) {
                    window.location = '/download-floor/' + floor.id;
                }
            };

            $scope.delete = function (floor) {
                confirmModal.open({
                    onConfirm: function () {
                        api.delete('floor/' + floor.id, {}, function () {
                            $scope.init();
                        });
                    },
                    message: 'Do you really want to delete the floor?'
                });
            };

            $scope.create = function () {
                if ($scope.isEditBuilding) {
                    $state.go('floors.create', {
                        building: $scope.building.id
                    });
                } else {
                    $state.go('floors.create');
                }
            };

            $scope.import = function () {
                $state.go('floors.import');
            };

            $scope.open_date_at_from_popup = function () {
                $scope.opened_date_at_from_popup = true;
            };

            $scope.open_date_at_to_popup = function () {
                $scope.opened_date_at_to_popup = true;
            };

            $scope.closeAlert = function () {
                delete $scope.alert;
            }

            $scope.floorName = function (type) {
                return $scope.floorTypes.find(function(element) {
                    return element.code == type;
                }).name
            }
        }]);
})(angular.module('app'));
