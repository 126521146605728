/**
 * Created by valik35 on 21.10.16.
 */
(
	function (angular) {
		var app = angular.module('app');
		app.controller('BuildingListController', function ($scope, $uibModal, $state, $stateParams, confirmModal, moment, api) {

			$scope.alert = $stateParams.alert;

			$scope.filter = {
				id: null,
				name: null,
				owner_name: null,
				access_level: null,
				current_page: 1,
				module: 'site',
				date_field: 'created_at',
                type: null,
                units_count: null,
                floors_count: null,
                offered: null
			};

			$scope.sort = {
				field: 'id',
				order: 'desc'
			};

            $scope.accessLevels = [
                {name: 'All', code: null},
                {name: 'Private', code: 'private'},
                {name: 'Test', code: 'test'},
                {name: 'Public', code: 'public'}
            ];

            $scope.access_level = {};

            $scope.init = function () {
                loadBuildings();
                $scope.access_level = $scope.accessLevels[0];
            };

			function loadBuildings(){
                $scope.filter.access_level = $scope.access_level.code;
				api.get(api.actions.building, angular.extend({}, mapFilter($scope.filter), $scope.sort), function (response) {
					$scope.buildings = response.data;

					$scope.filter.total    = response.total;
					$scope.filter.page     = response.current_page;
					$scope.filter.per_page = response.per_page;

				});
			}

			function mapFilter(filter) {
				var copy = angular.extend({}, filter);

                if (copy.date_at_from) {
                    copy.date_at_from = moment(copy.date_at_from).format();
                }

                if (copy.date_at_to) {
                    copy.date_at_to = moment(copy.date_at_to).hours(23).minutes(59).seconds(59).format();
                }

                if ($stateParams.page) {
                    copy.page = $stateParams.page;
                    $stateParams.page = null;
                }

				return copy;
			}

			$scope.delete = function (building) {
				confirmModal.open({
					onConfirm: function () {
                        api.delete(api.actions.building + '/' + building.id, angular.extend({}, mapFilter($scope.filter), $scope.sort), function () {
                            loadBuildings()
                        });
					},
                    message: 'Do you really want to delete building ' + building.name + '?'
				});
			};

			$scope.edit = function (id) {
				$state.go('building::edit', {
					id: id,
                    page: $scope.filter.page
				});
			};

			$scope.pageChanged = function () {
				loadBuildings();
			};

			$scope.refresh = function () {
				loadBuildings();
			};

			$scope.sortBy = function (field) {

				if($scope.sort.field == field) {
					$scope.sort.order == 'desc' ? $scope.sort.order = 'asc' : $scope.sort.order = 'desc';
				} else {
					$scope.sort.order = 'desc';
				}

				$scope.sort.field = field;

				loadBuildings();
			};

			$scope.open_date_at_from_popup = function () {
				$scope.opened_date_at_from_popup = true;
			};

			$scope.open_date_at_to_popup = function () {
				$scope.opened_date_at_to_popup = true;
			};

			$scope.closeAlert = function () {
				delete $scope.alert;
			};
		});
	}
)(angular);
