(function (app) {
    app.controller('DashboardDeletedUsersController', ["$scope", "api", "moment", "confirmModal", "$stateParams", function ($scope, api, moment, confirmModal, $stateParams) {
            $scope.users = {};

            $scope.filters = {
                id: null,
                name: null,
                email: null,
                role: $stateParams.role,
                module: 'dashboard',
                date_field: 'deleted_at'
            };

            $scope.sort = {
                field: 'deleted_at',
                order: 'desc'
            };

            $scope.getUsers = function () {
                if ($scope.date_at_to) {
                    $scope.filters.date_at_to = moment($scope.date_at_to).hours(23).minutes(59).seconds(59).format();
                }
                api.get('user', angular.extend({}, $scope.filters, $scope.sort), function (response) {
                    $scope.users = response.data;
                    $scope.filters.total = response.total;
                    $scope.filters.per_page = response.per_page;
                    $scope.filters.page = response.current_page;
                });
            };

            $scope.sortBy = function (field) {
                if ($scope.sort.field === field) {
                    $scope.sort.order = $scope.sort.order === 'desc' ? 'asc' : 'desc';
                } else {
                    $scope.sort.order = 'desc';
                }

                $scope.sort.field = field;
                $scope.getUsers();
            };

            $scope.forceDeleteUsers = function (user) {
                confirmModal.open({
                    onConfirm: function () {
                        var willDeleted = [];

                        if (user) {
                            willDeleted.push(user.id);
                        } else {
                            for (var i = 0; i < $scope.users.length; i++) {
                                willDeleted.push($scope.users[i].id);
                            }
                        }

                        api.delete('force-delete-users', {deleting_ids: willDeleted}, function () {
                            $scope.alert = {
                                message: 'User was successfully deleted',
                                type: 'success'
                            };
                            $scope.getUsers();
                        });
                    },
                    message: 'Do you really want delete users which you see on this page?'
                });
            };

            $scope.restore = function (user) {
                confirmModal.open({
                    onConfirm: function () {
                        api.put('restore-user/' + user.id, {}, function () {
                            $scope.alert = {
                                message: 'User was successfully restored?',
                                type: 'success'
                            };
                            $scope.getUsers();
                        });
                    },
                    message: 'Do you really want to restore user ' + user.name + '?'
                });
            };

            $scope.open_date_at_from_popup = function () {
                $scope.opened_date_at_from_popup = true;
            };

            $scope.open_date_at_to_popup = function () {
                $scope.opened_date_at_to_popup = true;
            };

            $scope.closeAlert = function () {
                delete $scope.alert;
            };
        }]);
})(angular.module('app'));
