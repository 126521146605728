(function (app) {

    app.controller('DashboardDeletedOwnersController', ["$scope", "api", "moment", "confirmModal", "$stateParams", function ($scope, api, moment, confirmModal, $stateParams) {

            $scope.filters = {
                id: null,
                name: null,
                email: null,
                role: $stateParams.role,
                module: 'dashboard',
                date_field: 'deleted_at'
            };

            $scope.sort = {
                field: 'deleted_at',
                order: 'desc'
            };

            $scope.selectedBuilding = {
                building: {}
            };

            $scope.owners = {};


            $scope.getOwners = function () {
                $scope.filters.building = $scope.selectedBuilding.building.id;

                if ($scope.date_at_to) {
                    $scope.filters.date_at_to = moment($scope.date_at_to).hours(23).minutes(59).seconds(59).format();
                }
                api.get('user', angular.extend({}, $scope.filters, $scope.sort), function (response) {
                    $scope.owners = response.data;
                    $scope.filters.total = response.total;
                    $scope.filters.per_page = response.per_page;
                    $scope.filters.page = response.current_page;
                });
            };

            $scope.getBuildings = function () {
                api.get('dictionary/buildings', null, function (response) {
                    $scope.buildings = response;
                    $scope.buildings.unshift({name: 'All', id: null});
                    $scope.selectedBuilding.building = $scope.buildings[0];
                });
            };

            $scope.getBuildings();

            $scope.refresh = function () {
                $scope.getOwners();
            };

            $scope.sortBy = function (field) {
                if ($scope.sort.field === field) {
                    $scope.sort.order = $scope.sort.order === 'desc' ? 'asc' : 'desc';
                } else {
                    $scope.sort.order = 'desc';
                }

                $scope.sort.field = field;
                $scope.getOwners();
            };

            $scope.restore = function (owner) {
                confirmModal.open({
                    onConfirm: function () {
                        api.put('restore-user/' + owner.id, {}, function () {
                            $scope.getOwners();
                        });
                    },
                    message: 'Do you really want to restore owner ' + owner.name + '?'
                });
            };

            $scope.forceDeleteOwners = function (owner) {
                confirmModal.open({
                    onConfirm: function () {
                        var willDeleted = [];
                        if (owner) {
                            willDeleted.push(owner.id);
                        } else {
                            for (var i = 0; i < $scope.owners.length; i++) {
                                willDeleted.push($scope.owners[i].id);
                            }
                        }

                        api.delete('force-delete-owners', {deleting_ids: willDeleted}, function () {
                            $scope.alert = {
                                message: 'Owner was successfully deleted',
                                type: 'success'
                            };
                            $scope.getOwners();
                        });
                    },
                    message: 'Do you really want to delete ' + (owner ? 'owner ' + owner.name + '?' : 'owners which you see on this page?')
                });
            };

            $scope.open_deleted_at_from_popup = function () {
                $scope.opened_deleted_at_from_popup = true;
            };

            $scope.open_deleted_at_to_popup = function () {
                $scope.opened_deleted_at_to_popup = true;
            };
        }]);
})(angular.module('app'));