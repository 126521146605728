(function (app) {

    app.controller('InviteIndexController', ["$scope", "api", "moment", "confirmModal", "$controller", "$state", "$stateParams",
        function ($scope, api, moment, confirmModal, $controller, $state, $stateParams) {

        $scope.filters = {
            id: null,
            name: null,
            email: null,
            status: null
        };

        $scope.sort = {
            field: 'id',
            order: 'desc'
        };

        $scope.inviteStatuses = [
            {name: 'All', code: null},
            {name: 'Created', code: 'created'},
            {name: 'Approved', code: 'approved'},
            {name: 'Rejected', code: 'canceled'}
        ];

        $scope.buildings = [];

        $scope.selectedBuilding = {
            building: {}
        };

        $scope.status = $scope.inviteStatuses[0];

        $scope.alert = $stateParams.alert;

        $scope.init = function () {
            $scope.getBuildings();
            $scope.getInvites($scope.isEditBuilding);
        };

        $scope.getInvites = function (editBuilding) {
            $scope.isEditBuilding = editBuilding;
            if ($scope.isEditBuilding) {
                $scope.buildingEditScope = $scope.$new();
                $controller('BuildingEditController', {$scope: $scope.buildingEditScope});
                $scope.building = {id: $scope.buildingEditScope.building};
                $scope.filters.building = $scope.buildingEditScope.building;
            }

            if (!$scope.isEditBuilding) {
                $scope.filters.building = $scope.selectedBuilding.building.id;
            }

            $scope.filters.status = $scope.status.code;
            if ($scope.updated_at_to) {
                $scope.filters.updated_at_to = moment($scope.updated_at_to).hours(23).minutes(59).seconds(59).format();
            }
            if ($scope.created_at_to) {
                $scope.filters.created_at_to = moment($scope.created_at_to).hours(23).minutes(59).seconds(59).format();
            }
            api.get('invites', angular.extend({}, $scope.filters, $scope.sort), function (response) {
                $scope.invites = response.data;
                $scope.filters.total = response.total;
                $scope.filters.per_page = response.per_page;
                $scope.filters.page = response.current_page;
            })
        };

        $scope.getBuildings = function () {
            api.get('invite-buildings', {}, function (response) {
                $scope.buildings = response;
                $scope.buildings.unshift({name: 'All', id: null});
                $scope.selectedBuilding.building = $scope.buildings[0];
            });
        };

        $scope.sortBy = function (field) {
            if ($scope.sort.field === field) {
                $scope.sort.order = $scope.sort.order === 'desc' ? 'asc' : 'desc';
            } else {
                $scope.sort.order = 'desc';
            }

            $scope.sort.field = field;
            $scope.getInvites($scope.isEditBuilding);
        };

        $scope.create = function () {
            if ($scope.isEditBuilding) {
                $state.go('invite.new', {
                    building: $scope.building.id
                });
            } else {
                $state.go('invite.new');
            }
        };

        $scope.import = function () {
            if ($scope.isEditBuilding) {
                $state.go('invite.import', {
                    building: $scope.building.id
                });
            } else {
                $state.go('invite.import');
            }
        };

        $scope.delete = function (invite) {
            confirmModal.open({
                onConfirm: function () {
                    api.delete('invite/' + invite.id, {}, function () {
                        $scope.getInvites($scope.isEditBuilding);
                    });
                },
                message: 'Do you really want to delete invite ' + invite.id + '?'
            });
        };

        $scope.open_updated_at_from_popup = function () {
            $scope.opened_updated_at_from_popup = true;
        };

        $scope.open_updated_at_to_popup = function () {
            $scope.opened_updated_at_to_popup = true;
        };

        $scope.open_created_at_from_popup = function () {
            $scope.opened_created_at_from_popup = true;
        };

        $scope.open_created_at_to_popup = function () {
            $scope.opened_created_at_to_popup = true;
        };

        $scope.closeAlert = function () {
            delete $scope.alert;
        };
    }]);
})(angular.module('app'));