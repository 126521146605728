(function (app) {
    app.controller('DashboardDeletedFloorsController', ["$scope", "api", "moment", "confirmModal", "capitalizeFilter", function ($scope, api, moment, confirmModal, capitalizeFilter) {

            $scope.filters = {
                id: null,
                name: null,
                owner: null,
                module: 'dashboard',
                date_field: 'deleted_at',
                units_count: null
            };

            $scope.sort = {
                field: 'deleted_at',
                order: 'desc'
            };

            $scope.floors = [];

            $scope.buildings = [];

            $scope.type = {};

            $scope.selectedBuilding = {
                building: {}
            };

            $scope.floorTypes = [
                {name: 'All', code: null},
                {name: 'Basement', code: 'basement'},
                {name: 'Floor', code: 'floor'},
                {name: 'Roof', code: 'roof'},
                {name: 'Mezzanine', code: 'mezzanine'},
                {name: 'Ground floor', code: 'ground_floor'}
            ];

            $scope.init = function () {
                $scope.type = $scope.floorTypes[0];
                $scope.getFloors();
                $scope.getBuildings();
            };

            $scope.getBuildings = function () {
                api.get('floor-buildings', {module: $scope.filters.module}, function (response) {
                    $scope.buildings = response;
                    $scope.buildings.unshift({name: 'All', id: null});
                    $scope.selectedBuilding.building = $scope.buildings[0];
                });
            };

            $scope.getFloors = function () {
                $scope.filters.building = $scope.selectedBuilding.building.id;
                $scope.filters.type = $scope.type.code;
                if ($scope.date_at_to) {
                    $scope.filters.date_at_to = moment($scope.date_at_to).hours(23).minutes(59).seconds(59).format();
                }
                api.get('floors', angular.extend({}, $scope.filters, $scope.sort), function (response) {
                    $scope.floors = response.data;
                    $scope.filters.total = response.total;
                    $scope.filters.per_page = response.per_page;
                    $scope.filters.page = response.current_page;
                });
            };

            $scope.forceDelete = function (floor) {
                confirmModal.open({
                    onConfirm: function () {
                        api.delete('dashboard/floor/' + floor.id, {}, function () {
                            $scope.alert = {
                                message: 'Floor was successfully deleted',
                                type: 'success'
                            };
                            $scope.getFloors();
                        });
                    },
                    message: 'Do you really want to delete the floor ' + capitalizeFilter(floor.type) + '?'
                });
            };

            $scope.forceDeleteAll = function () {
                confirmModal.open({
                    onConfirm: function () {
                        var willDeleted = [];

                        for (var i = 0; i < $scope.floors.length; i++) {
                            willDeleted.push($scope.floors[i].id);
                        }

                        api.put('dashboard/floors', {floors: willDeleted}, function () {
                            $scope.alert = {
                                message: $scope.floors.length + ' floors was successfully deleted',
                                type: 'success'
                            };
                            $scope.getFloors();
                        });
                    },
                    message: 'Do you really want to delete floors which you see on this page?'
                });
            };

            $scope.restore = function (floor) {
                confirmModal.open({
                    onConfirm: function () {
                        api.put('dashboard/floor/' + floor.id, {}, function (response) {
                            if (response.message) {
                                $scope.alert = {
                                    message: response.message,
                                    type: 'warning'
                                };
                            } else {
                                $scope.alert = {
                                    message: 'Floor was successfully restored',
                                    type: 'success'
                                };
                                $scope.getFloors();
                            }
                        });
                    },
                    message: 'Do you really want to restore the ' + (capitalizeFilter(floor.type)) + '?'
                });
            };

            $scope.sortBy = function (field) {
                if ($scope.sort.field === field) {
                    $scope.sort.order = $scope.sort.order === 'desc' ? 'asc' : 'desc';
                } else {
                    $scope.sort.order = 'desc';
                }

                $scope.sort.field = field;
                $scope.getFloors();
            };

            $scope.closeAlert = function () {
                delete $scope.alert;
            };

            $scope.floorName = function (type) {
                return $scope.floorTypes.find(function(element) {
                    return element.code == type;
                }).name
            }

            $scope.open_date_at_from_popup = function () {
                $scope.opened_date_at_from_popup = true;
            };

            $scope.open_date_at_to_popup = function () {
                $scope.opened_date_at_to_popup = true;
            };
        }]);
})(angular.module('app'));