/**
 * Created by valik35 on 21.10.16.
 */
(
    function (angular) {
        var app = angular.module('app');
        app.controller('UserEditController', function ($scope, $state, $stateParams, api, capitalizeFilter, confirmModal, countries) {

            $scope.backUrl = $stateParams.role == 'user' ? 'user' : 'owner'

            $scope.user = {
                role: $stateParams.role,
                phone_country_code: {
                    "name": "United Arab Emirates",
                    "dial_code": "+971",
                    "code": "AE"
                },
            }
            $scope.errors = {};
            $scope.countries = countries;

            loadUser();
            function loadUser() {
                api.get(api.actions.user + '/' + $stateParams.id, null, function (response) {
                        $scope.user = response;
                        $scope.user.role = $stateParams.role;
                        if($scope.user.phone_country_code) {

                            countries.forEach(function (element, index, array) {
                                if(element.dial_code == '+' + $scope.user.phone_country_code) {
                                    $scope.user.phone_country_code = element;
                                }
                            });
                        } else {
                            $scope.user.phone_country_code = {
                                "name": "United Arab Emirates",
                                "dial_code": "+971",
                                "code": "AE"
                            };
                        }
                    },
                    function (data) {
                        $scope.errors = data;
                    });
            }

            function mapUser(user) {
                var oldUser = angular.extend({}, user);
                oldUser.phone_country_code = oldUser.phone_country_code.dial_code.replace(/\+/, '');

                if (!oldUser.phone_number) delete oldUser.phone_number;

                return oldUser;
            }

            $scope.updateUser = function () {
                api.patch(api.actions.user + '/' + $stateParams.id, angular.extend({}, mapUser($scope.user), {
                        action : 'update'
                    }),
                    function (response) {
                        $state.go($scope.backUrl, {
                            alert: {
                                message: capitalizeFilter($scope.user.role) + ' was successfully updated',
                                type: 'success'
                            }
                        });
                    },
                    function (data) {
                        $scope.errors = data;
                    });
            }

            $scope.changePassword = function () {
                api.patch(api.actions.user + '/' + $stateParams.id,  angular.extend({}, $scope.user, {
                        action : 'change_password'
                    }),
                    function (response) {
                        $state.go($scope.backUrl, {
                            alert: {
                                message: capitalizeFilter($scope.user.role) + ' password was successfully changed',
                                type: 'success'
                            }
                        });
                    },
                    function (data) {
                        $scope.errors = data;
                    });
            }

            $scope.cancel = function () {
                confirmModal.open({
                    message: 'Do you want to leave this page and lose your changes?',
                    onConfirm: function () {
                        $state.go($scope.backUrl);
                    }
                });
            };
        });
    }
)(angular);