/**
 * Created by valik35 on 21.10.16.
 */
(
    function (angular) {
        var app = angular.module('app');

        app.service('auth', function($state, api, localStorageService) {

            this.ROLE_ADMIN          = 'admin';
            this.ROLE_BUILDING_OWNER = 'building_owner';

            this.redirect_url = 'login';

            this.check = function () {
                var check = localStorageService.get('auth_token') &&
                    localStorageService.get('role') &&
                    localStorageService.get('token_expire_at') >= moment().unix()

                if(!check) {
                    this.logout();
                }

                return check;
            };
            this.login = function (user, onFail) {
                api.post(api.actions.login, user,
                    function (response) {
                    console.log(response);
                        localStorage.setItem('ls.auth_token', response.token.key);
                        localStorage.setItem('ls.token_expire_at', response.token.expired_at);
                        localStorage.setItem('ls.role', response.user.role);
                        localStorageService.set('user', response.user);

                        $state.go('dashboard');
                    }, onFail);

            };

            this.isAdmin = function () {
                return localStorageService.get('auth_token') && localStorageService.get('role') == this.ROLE_ADMIN;
            };

            this.hasRole = function (role) {
                return role === localStorageService.get('role');
            };

            this.logout = function () {
                localStorageService.remove('auth_token', 'role', 'user');
            };

            this.user = function () {
                return localStorageService.get('user')
            }

            this.token = function () {
                return localStorageService.get('auth_token')
            }

        });
    }
)(angular);