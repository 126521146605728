/**
 * Created by valik35 on 21.10.16.
 */
(
	function (angular) {
		var app = angular.module('app');

		app.config(function($middlewareProvider) {

			$middlewareProvider.map({ //@todo: refactor change localstorage to auth service actions
				'only-admin': function onlyAdminMiddleware(localStorageService, auth) {
					if(localStorageService.get('auth_token') && localStorageService.get('role') == auth.ROLE_ADMIN) {
						this.next();
					}

					this.redirectTo('login');
				},

				'only-building-owner': function onlyBuildingOwnerMiddleware(localStorageService, auth) {
					if(localStorageService.get('auth_token') && localStorageService.get('role') == auth.ROLE_BUILDING_OWNER) {
						this.next();
					}

					this.redirectTo('login');
				},

				'only-admin-or-building-owner': function onlyAdminOrBuildingOwnerMiddleware(localStorageService, auth) {
					if(localStorageService.get('auth_token') && (localStorageService.get('role') == auth.ROLE_BUILDING_OWNER || localStorageService.get('role') == auth.ROLE_ADMIN)) {
						this.next();
					}

					this.redirectTo('login');
				},

				'auth': function onlyAdminOrBuildingOwnerMiddleware(auth) {
					auth.check() ? this.next() : this.redirectTo('login');
				},


				'only-guest': function onlyGuestMiddleware(localStorageService) {
					if(localStorageService.get('auth_token') && localStorageService.get('role')) {
						this.redirectTo('home');
					}

					this.next();
				}

			});

		});
	}
)(angular);