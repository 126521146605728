(function (app) {

    app.controller("DashboardDeletedUnitsController", ["$scope", "api", "moment", "$stateParams", "$state", "confirmModal", "$controller",
        function ($scope, api, moment, $stateParams, $state, confirmModal, $controller) {

            $scope.units = {};

            $scope.selectedBuilding = {
                building: {}
            };

            $scope.selectedFloor = {
                floor: {}
            };

            $scope.building = {};
            $scope.floor = {};

            $scope.filters = {
                id: null,
                number: null,
                building: null,
                floor_number: null,
                type: null,
                use: null,
                number_of_bedrooms: null,
                building_offered: null,
                price: null,
                price_from: null,
                price_to: null,
                area_from: null,
                area_to: null,
                module: 'dashboard'
            };

            $scope.sort = {
                field: 'deleted_at',
                order: 'desc'
            };

            $scope.alert = $stateParams.alert;

            $scope.init = function () {
                $scope.getUnits($scope.isEditBuilding);
                $scope.getBuildings();
                $scope.getFloors();
            };

            $scope.getUnits = function (editBuilding) {
                $scope.isEditBuilding = editBuilding;
                if ($scope.isEditBuilding) {
                    $scope.buildingEditScope = $scope.$new();
                    $controller('BuildingEditController', {$scope: $scope.buildingEditScope});
                    $scope.building = {id: $scope.buildingEditScope.building};
                    $scope.filters.building = $scope.buildingEditScope.building;
                }

                if (!$scope.isEditBuilding) {
                    $scope.filters.building = $scope.selectedBuilding.building.id;
                }
                if($scope.selectedFloor.floor.number != 'All') {
                    $scope.filters.floor_number = $scope.selectedFloor.floor.number;
                } else {
                    $scope.filters.floor_number = null;
                }

                api.get('units', angular.extend({}, $scope.filters, $scope.sort), function (response) {
                    $scope.units = response.data;

                    $scope.filters.total = response.total;
                    $scope.filters.per_page = response.per_page;
                    $scope.filters.page = response.current_page;
                });
            };

            $scope.sortBy = function (field) {
                if ($scope.sort.field === field) {
                    $scope.sort.order = $scope.sort.order === 'desc' ? 'asc' : 'desc';
                } else {
                    $scope.sort.order = 'desc';
                }

                $scope.sort.field = field;
                $scope.getUnits($scope.isEditBuilding);
            };

            $scope.getBuildings = function () {
                api.get('unit-buildings', {module: $scope.filters.module}, function (response) {
                    $scope.buildings = response;
                    $scope.buildings.unshift({name: 'All', id: null});
                    $scope.selectedBuilding.building = $scope.buildings[0];
                });
            };

            $scope.getFloors = function () {
                api.get('unit-floors', {module: $scope.filters.module}, function (response) {
                    $scope.floors = response;
                    $scope.floors.unshift({number: 'All'});
                    $scope.selectedFloor.floor = $scope.floors[0];
                });
            };

            $scope.edit = function (unit) {
                $state.go('units.edit', {
                    id: unit.id,
                    building: $scope.building.id
                });
            };

            $scope.download = function (unit) {
                if (parseInt(unit.size)) {
                    window.location = '/download-unit/' + unit.id;
                }
            };

            $scope.delete = function (unit) {
                confirmModal.open({
                    onConfirm: function () {
                        api.delete('unit/' + unit.id, {}, function () {
                            $scope.init();
                        });
                    },
                    message: 'Do you really want to delete the unit?'
                });
            };

            $scope.create = function () {
                if ($scope.isEditBuilding) {
                    $state.go('units.create', {
                        building: $scope.building.id
                    });
                } else {
                    $state.go('units.create');
                }
            };
            
            $scope.restore = function (unit) {
                confirmModal.open({
                    onConfirm: function () {
                        api.put('dashboard/unit/' + unit.id, {}, function (response) {
                            if (response.message) {
                                $scope.alert = {
                                    message: response.message,
                                    type: 'warning'
                                };
                            } else {
                                $scope.alert = {
                                    message: 'Unit was successfully restored',
                                    type: 'success'
                                };
                                $scope.getUnits();
                            }
                        });
                    },
                    message: 'Do you really want to restore the unit?'
                });
            }

            $scope.forceDelete = function (unit) {
                confirmModal.open({
                    onConfirm: function () {
                        api.delete('dashboard/unit/' + unit.id, {}, function () {
                            $scope.alert = {
                                message: 'Unit was successfully deleted',
                                type: 'success'
                            };
                            $scope.getUnits();
                        });
                    },
                    message: 'Do you really want to delete the unit?'
                });
            }
            
            $scope.forceDeleteAll = function () {
                confirmModal.open({
                    onConfirm: function () {
                        var willDeleted = [];

                        for (var i = 0; i < $scope.units.length; i++) {
                            willDeleted.push($scope.units[i].id);
                        }

                        api.delete('dashboard/units', {units: willDeleted}, function () {
                            $scope.alert = {
                                message: $scope.floors.length + ' units was successfully deleted',
                                type: 'success'
                            };
                            $scope.getUnits();
                        });
                    },
                    message: 'Do you really want to delete units which you see on this page?'
                });
            }

            $scope.import = function () {
                $state.go('units.import');
            };

            $scope.open_date_at_from_popup = function () {
                $scope.opened_date_at_from_popup = true;
            };

            $scope.open_date_at_to_popup = function () {
                $scope.opened_date_at_to_popup = true;
            };

            $scope.closeAlert = function () {
                delete $scope.alert;
            }

            $scope.mapBedrooms = function (number) {
                number = +number;

                var res = '';
                if (number == 0) {
                    res = 'studio';
                } else if (number == 1) {
                    res = '1 bedroom';
                } else if (number > 1 && number < 6) {
                    res = number + ' bedrooms';
                } else if (number > 5) {
                    res = '6+ bedrooms';
                }

                return res;
            }

        }]);
})(angular.module('app'));
