(function (app) {
    app.controller('UnitEditController', ["$scope", "api", "$stateParams", "confirmModal", "$state", function ($scope, api, $stateParams, confirmModal, $state) {

        $scope.selectedBuilding = {
            building: {}
        };

        $scope.selectedFloor = {
            floor: {}
        };
        $scope.errors = {};

        $scope.init = function () {
            $scope.getUnit();
        };

        $scope.unit = {
            type: 'villa',
            use: 'residential',
            bedroomsCount: '0',
            offered: 'sale',
            floor: {
                building: null
            }
        };


        $scope.disabled = false;
        $scope.unitDisabled = false;

        $scope.getUnit = function () {
            if($stateParams.id) {
                api.get('unit/' + $stateParams.id, {}, function (response) {
                    $scope.unit = response;
                    $scope.unit.bedroomsCount = `${$scope.unit.bedroomsCount}`;
                    $scope.selectedFloor.floor = $scope.unit.floor;

                    $scope.getBuildings();
                });
            } else {
                $scope.getBuildings();
            }
        };

        $scope.getBuildings = function () {
            if ($stateParams.building) {
                $scope.disabled = true;
            }
            return api.get('unit-buildings', {module: 'site'}, function (response) {
                $scope.buildings = response;
                for (var i = 0; i < $scope.buildings.length; i++) {
                    if ($scope.buildings[i].id == $scope.unit.floor.building_id) {
                        $scope.selectedBuilding.building = $scope.buildings[i];
                    }
                    if ($stateParams.building) {
                        $scope.disabled = true;
                        for (var i = 0; i < $scope.buildings.length; i++) {
                            if ($scope.buildings[i].id == $stateParams.building) {
                                $scope.selectedBuilding.building = $scope.buildings[i];
                            }
                        }
                    }

                    $scope.buildings[i].floors = $scope.buildings[i].floors.map(floor => {
                        if(floor.type == 'basement') {
                            floor.number = -floor.number;
                        }

                        return floor;
                    });
                }
            });
        };

        $scope.floorLetter = function (floor) {
            if(!floor.type){
                return '';
            }
            return floor.type.charAt(0).toUpperCase();
        };

        $scope.setValues = function () {
            $scope.emptySelectedFloor();
            $scope.setUnitType();
        };

        $scope.emptySelectedFloor = function () {
            $scope.selectedFloor = {
                floor: {}
            };
        };
        $scope.setUnitType = function () {
            $scope.unit.type = $scope.selectedBuilding.building.type;
        }

        $scope.back = function () {
            confirmModal.open({
                onConfirm: function () {
                    if ($stateParams.building) {
                        $state.go('building::edit', {
                            id: $stateParams.building,
                            page: $stateParams.page,
                            tab: 2
                        });
                    } else {
                        $state.go('units');
                    }
                },
                message: 'Do you want to leave this page and lose your changes?'
            });
        };

        $scope.save = function () {
            $scope.unit.building_id = $scope.selectedBuilding.building.id;
            $scope.unit.floor_id = $scope.selectedFloor.floor.id;

            var onSuccess = function () {
                if ($stateParams.building) {
                    $state.go('building::edit', {
                        id: $stateParams.building,
                        tab: 2,
                        page: $stateParams.page,
                        alert: {
                            message: 'Unit was successfully ' + ($scope.unit.id ? 'updated' : 'created'),
                            type: 'success'
                        }
                    });
                } else {
                    $state.go('units', {
                        alert: {
                            message: 'Unit was successfully ' + ($scope.unit.id ? 'updated' : 'created'),
                            type: 'success'
                        },
                        page: $stateParams.page
                    });
                }
            };
            if($scope.unit.id) {
                api.put('unit/' + $scope.unit.id, $scope.unit, onSuccess, function (errors) {
                    $scope.errors = errors;
                });
            } else {
                api.post('unit', $scope.unit, onSuccess, function (errors) {
                    $scope.errors = errors;
                });
            }
        };
    }]);
})(angular.module('app'));
