(function (app) {
    app.controller('DashboardDeletedBuildingsController', ["$scope", "api", "moment", "confirmModal", "auth", function ($scope, api, moment, confirmModal, auth) {

            $scope.filters = {
                id: null,
                name: null,
                owner_name: null,
                module: 'dashboard',
                date_field: 'deleted_at',
                type: null,
                units_count: null,
                floors_count: null,
                offered: null
            };

            $scope.sort = {
                field: 'deleted_at',
                order: 'desc'
            };

            $scope.accessLevels = [
                {name: 'All', code: null},
                {name: 'Public', code: 'public'},
                {name: 'Test', code: 'test'},
                {name: 'Private', code: 'private'}
            ];


            $scope.init = function () {
                $scope.level = $scope.accessLevels[0];
                $scope.getBuildings();
            };

            $scope.getBuildings = function () {
                $scope.filters.access_level = $scope.level.code;

                if ($scope.date_at_to) {
                    $scope.filters.date_at_to = moment($scope.date_at_to).hours(23).minutes(59).seconds(59).format();
                }
                
                api.get('building', angular.extend({}, $scope.filters, $scope.sort), function (response) {
                    $scope.buildings = response.data;
                    $scope.filters.total = response.total;
                    $scope.filters.per_page = response.per_page;
                    $scope.filters.page = response.current_page;
                });
            };

            $scope.sortBy = function (field) {
                if ($scope.sort.field === field) {
                    $scope.sort.order = $scope.sort.order === 'desc' ? 'asc' : 'desc';
                } else {
                    $scope.sort.order = 'desc';
                }

                $scope.sort.field = field;
                $scope.getBuildings();
            };

            $scope.forceDelete = function (build) {
                confirmModal.open({
                    onConfirm: function () {
                        api.delete('dashboard/force-delete/' + build.id, {}, function () {
                            $scope.alert = {
                                message: 'Building ' + build.name + ' was successfully deleted',
                                type: 'success'
                            };
                            $scope.getBuildings();
                        });
                    },
                    message: 'Do you really want to delete the building ' + build.name + '?'
                });
            };

            $scope.forceDeleteAll = function () {
                confirmModal.open({
                    onConfirm: function () {
                        var willDeleted = [];
                        for (var i = 0; i < $scope.buildings.length; i++) {
                            willDeleted.push($scope.buildings[i].id);
                        }

                        api.delete('dashboard/force-delete-all', {buildings: willDeleted}, function () {
                            $scope.getBuildings();
                        });
                    },
                    message: 'Do you really want to delete buildings which you see on this page?'
                });
            };

            $scope.restore = function (build) {
                var message = '';

                if (build.is_delete_user) {
                    message = "Owner of this building '" + build.name + "' was deleted. Would you like to restore building together with the owner?";
                } else {
                    message = "Do you really want to restore building " + build.name + "?";
                }

                confirmModal.open({
                    onConfirm: function () {
                        api.put('building-restore/' + build.id, {role: auth.user().role, user: true}, function () {
                            $scope.getBuildings();
                            $scope.alert = {
                                message: 'Building ' + build.name + ' was successfully restored',
                                type: 'success'
                            };
                        });
                    },
                    onCancel: function () {
                        if (build.is_delete_user) {
                            api.put('building-restore/' + build.id, {role: auth.user().role}, function () {
                                $scope.getBuildings();
                                $scope.alert = {
                                    message: 'Building ' + build.name + ' was successfully restored',
                                    type: 'success'
                                };
                            });
                        }
                    },
                    message: message,
                    useBreakWord: false
                });
            };

            $scope.open_date_at_from_popup = function () {
                $scope.opened_date_at_from_popup = true;
            };

            $scope.open_date_at_to_popup = function () {
                $scope.opened_date_at_to_popup = true;
            };

            $scope.closeAlert = function () {
                delete $scope.alert;
            }
        }]);
})(angular.module('app'));
