/**
 * Created by Valentine on 23.01.17.
 */
(
    function (angular) {
        var app = angular.module('app');

        app.controller('EditorController', function ($scope, auth, $state, $stateParams, api) {

            init();
            function init() {
                api.get(api.actions.editor + '/config', null, function (response) {
                        $scope.iframeUrl = response.editor_base_url +
                            '?auth_token=' + auth.token() +
                            '&user_id=' + auth.user().id +
                            '&user_name=' + auth.user().name +
                            '&user_role=' + auth.user().role;
                    },
                    function (data) {
                        $scope.errors = data;
                    });
            }


        });
    }
)(angular);