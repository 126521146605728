/**
 * Created by valik35 on 21.10.16.
 */

/*

 @todo: location picker
 @todo: building owner typehead
 */
(function (angular) {
        var app = angular.module('app');
        app.controller('BuildingEditController', function ($scope, $state, $stateParams, api, capitalizeFilter, confirmModal, $uibModal, NgMap, Upload, auth, countries) {
            $scope.backUrl = 'building';

            $scope.user = {
                role: $stateParams.role
            };

            $scope.errors = {};

            $scope.defaultMarker = {
                lat: 24.445227,
                lng: 54.379485
            };

            $scope.color_picker_options = {
                format: 'hex'
            };

            $scope.address = {
                name: ''
            };

            $scope.selectedOwner = {
                owner: ''
            };

            $scope.countries = countries;

            $scope.cities = [];

            $scope.activeTab = $stateParams.tab ? $stateParams.tab : 0;

            $scope.building = $stateParams.id;

            $scope.locationSelected = false;

            if ($stateParams.alert) {
                $scope.alert = $stateParams.alert;
            }

            $scope.init = function () {
                $scope.getBuilding();
            };

            $scope.loadOwners = function () {
                api.get(api.actions.dictionary.building_owners, null,
                    function (response) {
                        $scope.building_owners = response;
                        for (var i = 0; i < $scope.building_owners.length; i++) {
                            if ($scope.building_owners[i].id === $scope.building.user_id) {
                                $scope.selectedOwner.owner = $scope.building_owners[i];
                            }
                        }
                    },
                    function (data) {
                        $scope.errors = data;
                    });
            };

            $scope.getBuilding = function () {
                api.get('building/' + $stateParams.id, {}, function (response) {
                    $scope.building = response;
                    if (auth.isAdmin()) {
                        $scope.loadOwners();
                    }
                    if ($scope.building.lat && $scope.building.lng) {
                        $scope.defaultMarker.lat = $scope.building.lat;
                        $scope.defaultMarker.lng = $scope.building.lng;
                        $scope.geocode($scope.building.lat, $scope.building.lng);
                    } else {
                        $scope.building.lat = $scope.defaultMarker.lat;
                        $scope.building.lng = $scope.defaultMarker.lng;
                    }
                    $scope.building.lat = parseFloat($scope.building.lat).toFixed(6);
                    $scope.building.lng = parseFloat($scope.building.lng).toFixed(6);

                    countries.forEach(function (country) {
                        if(country.name == response.country) {
                            $scope.building.country = country;
                            return;
                        }
                    });

                    if(!$scope.building.country) {
                        $scope.building.country = {name: 'United Arab Emirates', code: 'AE'};
                    }

                    if(!$scope.building.access_level) {
                        $scope.building.access_level = 'public';
                    }
                });
            };

            $scope.emptySelectedCity = function () {
                $scope.building.city = '';
                $scope.building.district = '';
            };
            $scope.emptySelectedDistrict = function () {
                $scope.building.district = '';
            };

			function map_building(building) {
				var mapped_building = angular.extend({}, building);

				mapped_building.lat = building.lat;
				mapped_building.lng = building.lng;
                mapped_building.user_id = $scope.selectedOwner.owner.id;

                if (mapped_building.texture_ground === null || mapped_building.texture_ground === '') {
                    delete mapped_building.texture_ground;
                }

                if (mapped_building.texture_roof === null || mapped_building.texture_roof === '') {
                    delete mapped_building.texture_roof;
                }

                mapped_building.district = mapped_building.district === null ? '' : mapped_building.district;
                mapped_building.city = mapped_building.city === null ? '' : mapped_building.city;
                mapped_building.address_number = mapped_building.address_number === null ? '' : mapped_building.address_number;
                mapped_building.description = mapped_building.description === null ? '' : mapped_building.description;
                mapped_building.street = mapped_building.street === null ? '' : mapped_building.street;

				delete mapped_building.position;

                mapped_building.country = building.country.name;

				return mapped_building;
			};

            $scope.sumbit = function () {
                api.put('building/' + $stateParams.id, map_building($scope.building),
                    function () {
                        $state.go($scope.backUrl, {
                            alert: {
                                message: 'Building was successfully updated',
                                type: 'success'
                            },
                            page: $stateParams.page
                        });
                    },
                    function (data) {
                        $scope.errors = data;
                    });
            };

            $scope.updateMarker = function (event) {
                var ll = event.latLng;
                $scope.building = {
                    lat: ll.lat(),
                    lng: ll.lng()
                };
            };

            $scope.cancel = function () {
                confirmModal.open({
                    message: 'Do you want to leave this page and lose your changes?',
                    onConfirm: function () {
                        $state.go($scope.backUrl, {
                            page: $stateParams.page
                        });
                    }
                });
            };

            $scope.getCenter = function (event) {
                $scope.locationSelected = true;
                $scope.defaultMarker.lat = event.latLng.lat();
                $scope.defaultMarker.lng = event.latLng.lng();
                $scope.geocode(event.latLng.lat(), event.latLng.lng());
            };

            $scope.openMap = function () {
                $scope.modalMap = $uibModal.open({
                    scope: $scope,
                    templateUrl: 'templates/partial/modalMap.html',
                    backdrop: 'static'
                });

                $scope.modalMap.rendered.then(function () {
                    $scope.map = NgMap.initMap('map');
                    $scope.map.setCenter(new google.maps.LatLng($scope.defaultMarker.lat, $scope.defaultMarker.lng));
                    if ($scope.place) {
                        $scope.map.setCenter($scope.place.geometry.location);
                    }
                });

            };

            $scope.geocode = function (lat, lng) {
                var geocoder = new google.maps.Geocoder();
                var latlng = new google.maps.LatLng(lat, lng);
                geocoder.geocode({ 'latLng': latlng }, function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        var address = results[0];
                        angular.forEach(address.address_components, function(addressInfo) {
                            angular.forEach(addressInfo.types, function(addressType) {
                                switch (addressType) {
                                    case 'country':
                                        for (var i = 0; i < $scope.countries.length; i++) {
                                            if (addressInfo.short_name === $scope.countries[i].code) {
                                                $scope.building.country.name = $scope.countries[i].name;
                                                $scope.building.country.code = $scope.countries[i].code;
                                            }
                                        }
                                        break;
                                    case 'locality':
                                        $scope.building.city = addressInfo.short_name || '';
                                        break;
                                    case 'sublocality':
                                        $scope.building.district = addressInfo.short_name || '';
                                        break;
                                    case 'route':
                                        $scope.building.street = addressInfo.short_name || '';
                                        break;
                                    case 'street_number':
                                        $scope.building.address_number = addressInfo.short_name || '';
                                        break;
                                }
                            });
                        });
                    } else {
                        console.log('Geocoder failed due to: ' + status);
                    }
                    $scope.$apply();
                });
            };

            $scope.parseCoordinates = function () {
                if ($scope.address.name.indexOf(',') !== -1) {
                    if ($scope.address.name.split(',').length === 2) {
                        var lat = parseFloat($scope.address.name.slice(0, $scope.address.name.indexOf(',') - 1).replace('/ /g', ''));
                        var lng = parseFloat($scope.address.name.slice($scope.address.name.indexOf(',') + 1, $scope.address.name.length - 1).replace('/ /g', ''));

                        if (lat && lng) {
                            $scope.geocode(lat, lng);
                            $scope.building.lat = lat.toFixed(6);
                            $scope.building.lng = lng.toFixed(6);
                        }
                    }
                }
            };

            $scope.placeChange = function () {
                $scope.place = this.getPlace();
                $scope.building.lat = $scope.place.geometry.location.lat().toFixed(6);
                $scope.building.lng = $scope.place.geometry.location.lng().toFixed(6);
                $scope.defaultMarker.lat = $scope.place.geometry.location.lat().toFixed(6);
                $scope.defaultMarker.lng = $scope.place.geometry.location.lng().toFixed(6);
                $scope.geocode($scope.place.geometry.location.lat(),  $scope.place.geometry.location.lng());
            };

            $scope.closeMap = function () {
                $scope.modalMap.close();
            };

            $scope.useLocation = function () {
                if ($scope.locationSelected) {
                    $scope.building.lat = $scope.defaultMarker.lat.toFixed(6);
                    $scope.building.lng = $scope.defaultMarker.lng.toFixed(6);
                    $scope.geocode($scope.building.lat, $scope.building.lng);
                }
                $scope.locationSelected = false;
                $scope.modalMap.close();
            };

            $scope.closeAlert = function () {
                delete $scope.alert;
            };

            $scope.loadQR = function (file) {
                if(!file) {
                    return;
                }
                delete $scope.errors.location_qr_code;

                $scope.location_qr_code = file.name;
                api.post(api.actions.qr_to_address, {'location_qr_code' : file },
                    function (response) {
                        $scope.building.lat = response.lat;
                        $scope.building.lng = response.lng;
                        $scope.defaultMarker.lat = response.lat;
                        $scope.defaultMarker.lng = response.lng;
                        $scope.geocode($scope.building.lat, $scope.building.lng);
                    },
                    function (data) {
                        $scope.errors = data;
                    });
            };

            // Image Crop
            $scope.image = '';
            $scope.croppedImage = '';
            $scope.cropAttribute = '';
            $scope.cropWidth = 300;
            $scope.cropHeight = 300;

            $scope.maxImageSize = 2; // megabytes
            $scope.allowedImageFormats = ['jpeg', 'jpg', 'png'];

            $scope.openCrop = function (file, attribute, options) {
                $scope.cropAttribute = attribute;
                if(options.w) {
                    $scope.cropWidth = options.w;
                }
                if(options.h) {
                    $scope.cropHeight = options.h;
                }
                if(!file) {
                    return;
                }

                // validation
                delete $scope.errors['new_' + attribute];
                if(!$scope.allowedImageFormats.some(function(item) {
                        return file.type == 'image/' + item;
                    })) {
                    var attributeName = attribute[0].toUpperCase() + attribute.slice(1); // first to upper
                    $scope.errors['new_' + attribute] = [attributeName + ' must be of ' + $scope.allowedImageFormats.join(', ') + ' format.'];
                    return;
                }
                if (file.size > ($scope.maxImageSize * 1024 * 1024)) {
                    $scope.errors['new_' + attribute] = ['File is too big. The ' + attribute + ' may not be greater than ' + $scope.maxImageSize + ' megabytes.'];
                    return;
                }

                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function (evt) {
                    $scope.image = evt.target.result;
                    $scope.cropImageModal = $uibModal.open({
                        scope: $scope,
                        templateUrl: 'templates/partial/cropImageModal.html',
                        backdrop: 'static'
                    });
                };
            };

            $scope.cancelCrop = function (attribute) {
                delete $scope.building[attribute];
                delete $scope.building['new_' + attribute];
                $scope.cropImageModal.close();
            };

            $scope.applyCrop = function (croppedImage, attribute) {
                if(attribute == 'photo') {
                    $scope.building.new_photo = croppedImage;
                } else if(attribute == 'logo') {
                    $scope.building.new_logo = croppedImage;
                }
                $scope.cropImageModal.close();
            };
        });

    })(angular);
