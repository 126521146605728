/**
 * Created by valik35 on 21.10.16.
 */
(
	function (angular) {
		var app = angular.module('app');

		app.config(function ($stateProvider, $urlRouterProvider, $locationProvider) {
			$locationProvider.html5Mode(true);

			$stateProvider.state('base',
				{
					abstract: true,
					url: '',
					templateUrl: '../templates/base.html'
				})
				.state('home',
					{
						parent: 'base',
						url: '/',
						templateUrl: '../templates/home.html'
					})
				.state('login',
					{
						parent: 'base',
						url: '/login',
						controller: 'AuthController',
						templateUrl: '../templates/auth/login.html',
						middleware: 'only-guest',
						params: {
							alert: {
								message: null,
								type: null,
							}
						}
					})
				.state('password::reset',
					{
						parent: 'base',
						url: '/password/reset',
						controller: 'ResetPassController',
						templateUrl: '../templates/auth/password/reset.html',
						middleware: 'only-guest'
					})
				.state('password::new',
					{
						parent: 'base',
						url: '/password/reset/:token',
						controller: 'ResetPassController',
						templateUrl: '../templates/auth/password/new_password.html',
						middleware: 'only-guest'
					})
				.state('owner',
					{
						parent: 'base',
						url: '/owner',
						controller: 'UserListController',
						templateUrl: '../templates/user/list.html',
						middleware: 'only-admin',
						params: {
							role: 'owner',
							alert: {
								message: null,
								type: null
							}
						}
					})
                .state('owner::import',
                    {
                        parent: 'base',
                        url: '/owner/import',
                        controller: 'UserImportController',
                        templateUrl: '../templates/user/import.html',
                        middleware: 'only-admin',
                        params: {
                            role: 'owner',
                            alert: {
                                message: null,
                                type: null
                            }
                        }
                    })
				.state('owner::create',
					{
						parent: 'base',
						url: '/owner/create',
						controller: 'UserCreateController',
						templateUrl: '../templates/user/form.html',
						middleware: 'only-admin',
						params: {
							role: 'owner'
						}
					}
				)
				.state('owner::edit',
					{
						parent: 'base',
						url: '/owner/edit/:id',
						controller: 'UserEditController',
						templateUrl: '../templates/user/edit.html',
						middleware: 'only-admin',
						params: {
							role: 'owner',
							id: null
						}
					}
				)
				.state('user',
					{
						parent: 'base',
						url: '/user',
						controller: 'UserListController',
						templateUrl: '../templates/user/list.html',
						middleware: 'only-admin',
						params: {
							role: 'user',
							alert: {
								message: null,
								type: null,
							}
						}
					})
                .state('user::import',
                    {
                        parent: 'base',
                        url: '/user/import',
                        controller: 'UserImportController',
                        templateUrl: '../templates/user/import.html',
                        middleware: 'only-admin',
                        params: {
                            role: 'user',
                            alert: {
                                message: null,
                                type: null,
                            }
                        }
                    })
				.state('user::create',
					{
						parent: 'base',
						url: '/user/create',
						controller: 'UserCreateController',
						templateUrl: '../templates/user/form.html',
						middleware: 'only-admin',
						params: {
							role: 'user'
						}
					}
				)
				.state('user::edit',
					{
						parent: 'base',
						url: '/user/edit/:id',
						controller: 'UserEditController',
						templateUrl: '../templates/user/edit.html',
						middleware: 'only-admin',
						params: {
							role: 'user'
						}
					}
				)
                .state('user::profile',
                    {
                        parent: 'base',
                        url: '/user/profile',
                        controller: 'UserProfileController',
                        templateUrl: '../templates/user/profile.html',
                        middleware: 'only-admin-or-building-owner'
                    }
                )
				.state('dashboard',
					{
						parent: 'base',
						url: '/dashboard',
						controller: 'DashboardIndexController',
						templateUrl: '../templates/dashboard/index.html',
						middleware: 'only-admin-or-building-owner',
						params: {
							role: 'user',
                            alert: {
                                message: null,
                                type: null
                            }
						}
					}
				)
				.state('dashboard.deleted-owners',
					{
						parent: 'base',
						url: '/dashboard/deleted-owners',
						controller: 'DashboardDeletedOwnersController',
						templateUrl: '../templates/dashboard/deletedOwners.html',
						middleware: 'only-admin',
						params: {
							role: 'owner'
						}
					}
				)
				.state('dashboard.deleted-users',
					{
						parent: 'base',
						url: '/dashboard/deleted-users',
						controller: 'DashboardDeletedUsersController',
						templateUrl: '../templates/dashboard/deletedUsers.html',
						middleware: 'only-admin',
						params: {
							role: 'user'
						}
					}
				)
				.state('dashboard.deleted-buildings',
					{
						parent: 'base',
						url: '/dashboard/deleted-buildings',
						controller: 'DashboardDeletedBuildingsController',
						templateUrl: '../templates/dashboard/deletedBuildings.html',
						middleware: 'only-admin-or-building-owner',
						params: {
							role: 'user'
						}
					}
				)
				.state('dashboard.deleted-floors',
					{
						parent: 'base',
						url: '/dashboard/deleted-floors',
						controller: 'DashboardDeletedFloorsController',
						templateUrl: '../templates/dashboard/deletedFloors.html',
						middleware: 'only-admin-or-building-owner',
						params: {
							role: 'user'
						}
					}
				)
                .state('dashboard.deleted-units',
                    {
                        parent: 'base',
                        url: '/dashboard/deleted-units',
                        controller: 'DashboardDeletedUnitsController',
                        templateUrl: '../templates/dashboard/deletedUnits.html',
                        middleware: 'only-admin-or-building-owner',
                        params: {
                            role: 'user'
                        }
                    }
                )
				.state('building',
					{
						parent: 'base',
						url: '/building',
						controller: 'BuildingListController',
						templateUrl: '../templates/building/list.html',
						middleware: 'only-admin-or-building-owner',
						params: {
                            page: null,
							alert: {
								message: null,
								type: null
							}
						}
					})
				.state('building::create',
					{
						parent: 'base',
						url: '/building/create',
						controller: 'BuildingCreateController',
						templateUrl: '../templates/building/form.html',
						middleware: 'only-admin',
					}
				)
                .state('building::import',
					{
						parent: 'base',
						url: '/building/import',
						controller: 'BuildingImportController',
						templateUrl: '../templates/building/importBuilding.html',
						middleware: 'only-admin'
					}
				)
				.state('building::edit',
					{
						parent: 'base',
						url: '/building/edit/:id',
						controller: 'BuildingEditController',
						templateUrl: '../templates/building/edit.html',
						middleware: 'only-admin-or-building-owner',
						params: {
							tab: 0,
                            page: null,
                            alert: {
                                message: null,
                                type: null
                            }
						}
					}
				)
				.state('invite',
					{
						parent: 'base',
						url: '/invite',
						controller: 'InviteIndexController',
						templateUrl: '../templates/invite/index.html',
						middleware: 'only-admin-or-building-owner',
                        params: {
                            alert: {
                                message: null,
                                type: null
                            }
                        }
					}
				)
				.state('invite.new',
                    {
                        parent: 'base',
                        url: '/invite/new',
                        controller: 'InviteNewController',
                        templateUrl: '../templates/invite/newInvite.html',
                        middleware: 'only-admin-or-building-owner',
						params: {
							building: null
						}
                    }
			    )
				.state('invite.import',
					{
						parent: 'base',
						url: '/invite/import',
						controller: 'InviteImportController',
						templateUrl: '../templates/invite/importInvite.html',
						middleware: 'only-admin-or-building-owner',
						params: {
							building: null
						}
					}
                )
                .state('editor',
                    {
                        parent: 'base',
                        url: '/editor',
                        controller: 'EditorController',
                        templateUrl: '../templates/editor/index.html',
                        middleware: 'only-admin-or-building-owner',
                    }
                )
				.state('floors',
					{
						parent: 'base',
						url: '/floors',
						controller: 'FloorIndexController',
						templateUrl: '../templates/floor/list.html',
						middleware: 'only-admin-or-building-owner',
						params: {
							alert: {
								message: null,
								type: null
							}
						}
					}
				)
                .state('floors.import',
                    {
                        parent: 'base',
                        url: '/floors/import',
                        controller: 'FloorImportController',
                        templateUrl: '../templates/floor/import.html',
                        middleware: 'only-admin-or-building-owner',
                        params: {
                            alert: {
                                message: null,
                                type: null
                            }
                        }
                    }
                )
				.state('floors.create',
					{
						parent: 'base',
						url: '/floors/create',
						controller: 'FloorCreateController',
						templateUrl: '../templates/floor/form.html',
						middleware: 'only-admin-or-building-owner',
						params: {
							building: null
						}
					}
				)
				.state('floors.edit',
					{
						parent: 'base',
						url: '/floors/edit/:id',
						controller: 'FloorEditController',
						templateUrl: '../templates/floor/form.html',
						middleware: 'only-admin-or-building-owner',
						params: {
							building: null
						}
					}
				)
                .state('units',
                    {
                        parent: 'base',
                        url: '/unit',
                        controller: 'UnitIndexController',
                        templateUrl: '../templates/unit/list.html',
                        middleware: 'only-admin-or-building-owner',
                        params: {
                            alert: {
                                message: null,
                                page: null,
                                type: null
                            }
                        }
                    }
                )
                .state('units.import',
                    {
                        parent: 'base',
                        url: '/unit/import',
                        controller: 'UnitImportController',
                        templateUrl: '../templates/unit/import.html',
                        middleware: 'only-admin-or-building-owner',
                        params: {
                            alert: {
                                message: null,
                                type: null
                            }
                        }
                    }
                )
                .state('units.create',
                    {
                        parent: 'base',
                        url: '/unit/create',
                        controller: 'UnitEditController',
                        templateUrl: '../templates/unit/form.html',
                        middleware: 'only-admin-or-building-owner',
                        params: {
                            building: null,
							edit: true
                        }
                    }
                )
                .state('units.edit',
                    {
                        parent: 'base',
                        url: '/unit/edit/:id',
                        controller: 'UnitEditController',
                        templateUrl: '../templates/unit/form.html',
                        middleware: 'only-admin-or-building-owner',
                        params: {
                            building: null,
                            page: null,
                            edit: false
                        }
                    }
                )
                .state('error',
                    {
                        parent: 'base',
                        url: '*path',
                        controller: 'ErrorsController',
                        templateUrl: '../templates/errors.html',
                        params: {
                            alert: {
                                message: '404. Not Found.'
                            }
                        }
                    }
                );

            // $urlRouterProvider.otherwise('/');
		});
	}
)(angular);
